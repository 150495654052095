import * as React from 'react';
import { Grow, Typography, Paper, Popper, MenuItem, MenuList, Stack, Button, ClickAwayListener } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';


export default function MenuListComposition() {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const navigate = useNavigate()

    const theme = useTheme();

    const handleToggle = () => {
        navigate('/prequalify')
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const expressClick = (event) => {
        navigate('/prequalify')
        handleClose(event);
    };

    const sbaClick = (event) => {
        navigate('/prequalify')
        handleClose(event);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Stack direction="row" spacing={2}>

            <div>
            <a href='../prequalify' style={{ textDecoration: 'none' }}>
                <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    //onClick={handleToggle}
                    sx={{
                        color: 'white',
                        backgroundColor: theme.palette.secondary.main,
                        height: 35,
                        border: 'none',
                        margin: '0 0 0 8px',
                        paddingRight: '0px',
                        //fontSize: { xs: 12, sm: 14, md: 16 },
                        fontWeight: 500,
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.secondary.main, 0.5), // Lighter background color on hover
                        },
                        display: { xs: 'inline-flex', md: 'inline-flex' },
                        alignItems: 'center', // Align items vertically in flexbox
                    }}
                >
                    <Typography sx={{ fontSize: { xs: 12, sm: 16, md: 16 }, fontWeight: 600, marginRight: 1 }} fontWeight={600}>   {/* dark blue */}
                        GET PREQUALIFIED
                    </Typography>
                </Button>
                </a>
            </div>
        </Stack>
    );
}
