import React, { useEffect, useState, lazy, Suspense } from 'react';
import Navbar from "./components/Navbar2"
import NotFoundPage from "./components/NotFoundPage"

//import Home from "./components/Home"
//import About from "./components/About"
//import Contact from "./components/Contact"
//import SBAExplained from './components/SBAExplained'
//import FAQ from './components/FAQ'
//import Process from "./components/Process"
//import LearningCenter from './components/LearningCenter'
//import Forms from "./components/Forms"
//import Referral from "./components/Referral"
//import SBAExpress from "./components/SBAExpress"
//import SBA7a from "./components/SBA7a"
//import SafariTest from './components/SafariTest'

//import LoanUsesBusinessAcquisition from './components/LoanUsesBusinessAcquisition2'
//import LoanUsesExpansion from './components/LoanUsesExpansion'
//import LoanUsesRefinance from './components/LoanUsesRefinance'
//import LoanUsesEquipment from './components/LoanUsesEquipment'
//import ESOP from "./components/LoanUsesESOP"
//import LoanUsesRealEstate from './components/LoanUsesRealEstate'
//import LoanUsesStartUp from './components/LoanUsesStartUp'
//import LoanUsesWorkingCapital from "./components/LoanUsesWorkingCapital2"

//import IndustryPage from './components/IndustryPage'
//import IndustryVeterinary from './components/IndustryVeterinary'

//import Calculator from "./components/Calculator"
//import LoanTypeTable from "./components/LoanTypeTable"
//import PrequalifyExpress from "./components/PrequalifyExpress"
//import PrequalifyStandard from './components/PrequalifyStandard'
//import CreditPull from "./components/CreditPull"
//import CreditEndConcl from "./components/CreditEndConcl"
//import CreditEndNoScore from "./components/CreditEndNoScore"
//import CreditEndFrozen from "./components/CreditEndFrozen"
//import CreditEndCongrats from "./components/CreditEndCongrats"
//import Questionnaire from "./components/Questionnaire";
//import SubmitExit from "./components/SubmitExit";

//import WhySBA from "./components/WhySBA"

import useCustomUrlQuery from "./hooks/useCustomUrlQuery"

import { Routes, Route  } from 'react-router-dom';
import { Grid } from "@mui/material";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer2"
import { useTheme } from '@mui/material/styles';
import { Helmet } from "react-helmet-async"

import ReactGA from 'react-ga4';

const Home = lazy(() => import("./components/Home"))
const About = lazy(() => import("./components/About"))
const Contact = lazy(() => import("./components/Contact"))
const SBAExplained = lazy(() => import("./components/SBAExplained2"))
const FAQ = lazy(() => import("./components/FAQ"))
const Process = lazy(() => import("./components/Process"))
const LearningCenter = lazy(() => import("./components/LearningCenter"))
const Forms = lazy(() => import("./components/Forms"))
const Referral = lazy(() => import("./components/Referral"))
const SBAExpress = lazy(() => import("./components/SBAExpress"))
const SBA7a = lazy(() => import("./components/SBA7a"))
const SafariTest = lazy(() => import("./components/SafariTest"))
const LoanUsesBusinessAcquisition = lazy(() => import("./components/LoanUsesBusinessAcquisition2"))
const LoanUsesConstruction = lazy(() => import("./components/LoanUsesConstruction"))
const LoanUsesExpansion = lazy(() => import("./components/LoanUsesExpansion"))
const LoanUsesPartnerBuyout = lazy(() => import("./components/LoanUsesPartnerBuyout"))
const LoanUsesRefinance = lazy(() => import("./components/LoanUsesRefinance"))
const LoanUsesEquipment = lazy(() => import("./components/LoanUsesEquipment"))
const ESOP = lazy(() => import("./components/LoanUsesESOP"))
const LoanUsesRealEstate = lazy(() => import("./components/LoanUsesRealEstate"))
const LoanUsesStartUp = lazy(() => import("./components/LoanUsesStartUp"))
const LoanUsesWorkingCapital = lazy(() => import("./components/LoanUsesWorkingCapital2"))
const IndustryPage = lazy(() => import("./components/IndustryPage"))
const IndustryAutomotive = lazy(() => import("./components/IndustryAutomotive"))
const IndustryBusinessServices = lazy(() => import("./components/IndustryBusinessServices"))
const IndustryCarWash = lazy(() => import("./components/IndustryCarWash"))
const IndustryChildCare = lazy(() => import("./components/IndustryChildCare"))
const IndustryChiropractor = lazy(() => import("./components/IndustryChiropractor.js"))
const IndustryCPA = lazy(() => import("./components/IndustryCPA.js"))
const IndustryDental = lazy(() => import("./components/IndustryDental.js"))
const IndustryElderCare = lazy(() => import("./components/IndustryElderCare.js"))
const IndustryEngineering = lazy(() => import("./components/IndustryEngineering.js"))
const IndustryFitnessCenters = lazy(() => import("./components/IndustryFitnessCenters.js"))
const IndustryFuneralHome = lazy(() => import("./components/IndustryFuneralHome"))
const IndustryGasStation = lazy(() => import("./components/IndustryGasStation"))
const IndustryHotels = lazy(() => import("./components/IndustryHotels"))
const IndustryInsurance = lazy(() => import("./components/IndustryInsurance"))
const IndustryInvestmentAdvice = lazy(() => import("./components/IndustryInvestmentAdvice"))
const IndustryLandscaping = lazy(() => import("./components/IndustryLandscaping"))
const IndustryLawyer = lazy(() => import("./components/IndustryLawyer"))
const IndustryLiquorStores = lazy(() => import("./components/IndustryLiquorStores"))
const IndustryMedicalProvider = lazy(() => import("./components/IndustryMedicalProvider"))
const IndustryPersonalServices = lazy(() => import("./components/IndustryPersonalServices"))
const IndustryPharmacy = lazy(() => import("./components/IndustryPharmacy"))
const IndustryRestaurant = lazy(() => import("./components/IndustryRestaurant"))
const IndustryRetailStores = lazy(() => import("./components/IndustryRetailStores"))
const IndustrySpecialtyTrade = lazy(() => import("./components/IndustrySpecialtyTrade"))
const IndustryTrucking = lazy(() => import("./components/IndustryTrucking"))
const IndustryVeterinary = lazy(() => import("./components/IndustryVeterinary"))
const CalculatorDSCR = lazy(() => import("./components/CalculatorDSCR"))
const LoanTypeTable = lazy(() => import("./components/LoanTypeTable"))
const PrequalifyExpress = lazy(() => import("./components/PrequalifyExpress"))
const PrequalifyStandard = lazy(() => import("./components/PrequalifyStandard"))
const CreditPull = lazy(() => import("./components/CreditPull"))
const CreditEndConcl = lazy(() => import("./components/CreditEndConcl"))
const CreditEndNoScore = lazy(() => import("./components/CreditEndNoScore"))
const CreditEndFrozen = lazy(() => import("./components/CreditEndFrozen"))
const CreditEndCongrats = lazy(() => import("./components/CreditEndCongrats"))
const Questionnaire = lazy(() => import("./components/Questionnaire"))
const SubmitExit = lazy(() => import("./components/SubmitExit"))



function App() {
  ReactGA.initialize('G-QW1F1GDL23');

  const theme = useTheme();
  const [pricingTable, setPricingTable] = useState([])
  const [navBarHeight, setNavBarHeight] = useState(0)
  const [navBarHeight2, setNavBarHeight2] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const [showNavbar, setShowNavbar] = useState(true);
  const [express, setExpress] = useState(true)

  const [data, setData] = useState({
    amount: '',
    amtInteract: false,
    amountformatted: '',
    interest: '',
    prime: '',
    margin: '',
    term: '',
    payment: '',
    stepamount: 10000,
    minamount: '',
    maxamount: '',
    minage: 18,
    maxage: 110,
    webpagetitle: '',
    webpagecontent: '',
    apiinputpk: 0,
    privacypolicylink: 'https://captecusa.com/privacy-policy/',
    privacypolicytitle: '',
    datetimesession: new Date(),
    clientname: 'CapTec USA',
    calendlylink: null,
    sendingemail: '',
    colorstep: theme.palette.primary.dark,
    colorfont: theme.palette.primary.dark,
    colorbackground: theme.palette.secondary.contrastText,
    logospacing: 1,
    real_estate: false,
    showrealestate: true,
    qualifiedrate: true,
    customurl: '',
    reactKey: '0_0_0',
    minCredit: 0,
    startCredit: false,
    finishCredit: false,
  });

  const [creditResult, setCreditResult] = useState(0)
  const [open, setOpen] = React.useState(false);

  const [contactInfo, setContactInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    businessname: "",
    ssn: "",
    dob: null,
    address: "",
    city: "",
    state: "",
    zip: "",
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneError: false,
    businessError: false,
    contactconsent: false,
    privacyconsent: false,
    ssnError: false,
    dobError: false,
    addressError: false,
    cityError: false,
    stateError: false,
    zipError: false,
    datetimeadded: new Date(),
    ssnconsent: false,
    businesstwoyears: true,
    workingcapital: true
  });

  const [qstepdata, setQStepData] = useState({
    amountformatted: "",
    legalBusinessName: "",
    entityType: "",
    employees: "",
    TIN: "",
    dateBusinessEst: null,
    busStreet1: "",
    busStreet2: "",
    busCity: "",
    busState: "",
    busZip: "",
    ownernum: "",
    applicantID: "",
    website: "",

    owner1FirstName: "",
    owner1LastName: "",
    owner1Perc: "",
    owner1HomeAddress1: "",
    owner1HomeAddress2: "",
    owner1City: "",
    owner1State: "",
    owner1Zip: "",
    owner1Email: "",
    owner1Phone: "",
    owner1SSN: "",
    owner1SSNoriginal: "",

    owner2FirstName: "",
    owner2LastName: "",
    owner2Perc: "",
    owner2Email: "",
    owner2Phone: "",
    owner2SSN: "",

    owner3FirstName: "",
    owner3LastName: "",
    owner3Perc: "",
    owner3Email: "",
    owner3Phone: "",
    owner3SSN: "",

    owner4FirstName: "",
    owner4LastName: "",
    owner4Perc: "",
    owner4Email: "",
    owner4Phone: "",
    owner4SSN: "",

    owner5FirstName: "",
    owner5LastName: "",
    owner5Perc: "",
    owner5Email: "",
    owner5Phone: "",
    owner5SSN: "",

    legalBusinessNameError: false,
    entityTypeError: false,
    employeesError: false,
    TINError: false,
    dateBusinessEstError: false,
    busStreet1Error: false,
    busCityError: false,
    busStateError: false,
    busZipError: false,

    ownernumError: false,
    owner1FirstNameError: false,
    owner1LastNameError: false,
    owner1PercError: false,
    owner1HomeAddress1Error: false,
    owner1CityError: false,
    owner1StateError: false,
    owner1ZipError: false,
    owner1EmailError: false,
    owner1PhoneError: false,

    agreeTerms: false,
    submitted: 2,

    qDriversLicenseName: null,
    qBusTax1Name: null,
    qBusTax2Name: null,
    qPersonalTaxName: null

  });

  const [dscrData, setDSCRData] = useState({
    incomeBeforeTax: '$0',
    depreciation: '$0',
    amortization: '$0',
    other: '$0',
    noi: '$0',
    loanAmount: '$0',
    term: '10',
    interest: '10.25%',
    loanPayment: '$0',
    dscr: '0.0',
  })

  const [industry, setIndustry] = React.useState('0');
  const [industryError, setIndustryError] = React.useState(false);
  const [loanUse, setLoanUse] = React.useState('0');
  const [loanUseError, setLoanUseError] = React.useState(false);
  const [realEstate, setRealEstate] = React.useState('0');
  const [realEstateError, setRealEstateError] = React.useState(false);

  const [qualifyProfit, setQualifyProfit] = useState(null);
  const [qualifyProfitable, setQualifyProfitable] = useState(null);
  const [qualifyCitizen, setQualifyCitizen] = useState(null);
  const [qualifyTaxes, setQualifyTaxes] = useState(null);
  const [qualifyDelq, setQualifyDelq] = useState(null);
  const [qualifyDefault, setQualifyDefault] = useState(null);
  const [qualifyBankrupt, setQualifyBankrupt] = useState(null);
  const [qualifyIncarc, setQualifyIncarc] = useState(null);
  const [qualifyIndustry, setQualifyIndustry] = useState(null);


  const [driverslicense, setDriversLicense] = useState({ file: null, name: 'No file chosen', size: null, type: null, loading: false });
  const [year1bustax, setYear1BusTax] = useState({ file: null, name: 'No file chosen', size: null, type: null, loading: false });
  const [year2bustax, setYear2BusTax] = useState({ file: null, name: 'No file chosen', size: null, type: null, loading: false });
  const [personaltax, setPersonalTax] = useState({ file: null, name: 'No file chosen', size: null, type: null, loading: false });

  const [steps, setSteps] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false
  });

  const [isQuestionnaire, setIsQuestionnaire] = useState(false)


  useEffect(() => {
    let url = window.location.href
    setIsQuestionnaire(url.includes('questionnaire'))
  }, [window.location.href]);

  //console.log('isQuestionnaire : ', isQuestionnaire)
  //console.log(eligibility)

  function formatAmount(value) {
    if (!value) return value;
    //console.log(value)
    const amountValue = value.toString().replace(/[^\d]/g, '');
    let amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
    const amountValueTrimLength = amountValueTrim.length;
    if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
    if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
    if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
  }

  const roundToNearest = (number, roundingBase) => {
    return Math.round(number / roundingBase) * roundingBase;
  };

  const findItemByKey = (key) => {
    return pricingTable.find(item => item.ReactKey === key);
  };

  //console.log('pricing table: ', pricingTable)
  //console.log('data.amountformatted: ', data.amountformatted)
  //const { isLoading } = useQuery({ data, setData, qstepdata, setQStepData })


  //////////////////////////////// MOVED TO PREQUALIFY.JS FOR BETTER LIGHTHOUSE SCORE (makes small a difference)
  const { isCustomUrlLoading } = useCustomUrlQuery({
    data, setData, contactInfo, setContactInfo
    , qstepdata, setQStepData
    , driverslicense, setDriversLicense
    , year1bustax, setYear1BusTax
    , year2bustax, setYear2BusTax
    , personaltax, setPersonalTax
    , qualifyProfit, setQualifyProfit
    , qualifyProfitable, setQualifyProfitable
    , qualifyTaxes, setQualifyTaxes
    , qualifyDelq, setQualifyDelq
    , qualifyDefault, setQualifyDefault
    , qualifyBankrupt, setQualifyBankrupt
    , qualifyIncarc, setQualifyIncarc
    , qualifyCitizen, setQualifyCitizen
    , qualifyIndustry, setQualifyIndustry
    , steps, setSteps
  })

  useEffect(() => {
    if (isQuestionnaire) {
      // Run some logic when `anotherState` is true
      // You can put any logic that depends on `isCustomUrlLoading` or other variables here
      //console.log('Custom URL is loading:', isCustomUrlLoading);
    }
  }, [isQuestionnaire, isCustomUrlLoading]);




  //////////////////////////////// MOVED TO PREQUALIFY.JS FOR BETTER LIGHTHOUSE SCORE (makes small a difference)
  useEffect(() => {
    fetch('https://cwihm8emt4.execute-api.us-east-1.amazonaws.com/v1', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        // Include the request body if necessary
      }),
    })
      .then(function (serverPromise) {
        serverPromise.json()
          .then(function (j) {

            //console.log('useQuery props.data.logo: ',props.data.logo);
            //console.log('j: ', j);
            //console.log('jlogo: ',j.logo);
            //console.log(j.body);
            //console.log(JSON.parse(j.body));
            //console.log(JSON.parse(j.body)[0]);
            setPricingTable(JSON.parse(j.body))
            //console.log('pricingTable : ', pricingTable)
            //console.log('pricingTable[0] : ', pricingTable[0])
          })
      })

  }, []);


  //////////////////////////////// MOVED TO PREQUALIFY.JS FOR BETTER LIGHTHOUSE SCORE (makes small a difference)
  useEffect(() => {
    if (pricingTable.length === 0) {
      return; // Exit early if pricingTable is empty
    }

    const foundItem = findItemByKey(data.reactKey);
    if (!foundItem) {
      console.warn('Item not found');
      return; // Exit early if no item is found
    }

    //console.log('foundItem:', foundItem);

    // Calculate values
    const intcalc = (foundItem.Prime + foundItem.Margin) / 12;
    const termcalc = foundItem.Term * 12;
    const calc1 = (1 + intcalc) ** termcalc;
    const amt = roundToNearest((foundItem.MinVal + foundItem.MaxVal) / 2, 100000);
    const pmtcalc = amt * (intcalc * calc1) / (calc1 - 1);

    // Set data
    setData(s => ({
      ...s,
      amount: amt,
      amountformatted: formatAmount(amt),
      interest: foundItem.Prime + foundItem.Margin,
      prime: foundItem.Prime,
      margin: foundItem.Margin,
      term: foundItem.Term,
      payment: pmtcalc,
      minamount: foundItem.MinVal,
      maxamount: foundItem.MaxVal,
      minCredit: foundItem.MinCredit,
    }));
  }, [pricingTable]);

  //console.log('new Date() : ', new Date())
  //console.log('data : ', data)
  

  return (
    <div className="App" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', width: '100vw', overflow: 'hidden' }} >
      <Helmet>
        <title>CapTec USA</title>
        <meta name="description" content="SBA Experts - Business Loans after Business Hours. Welcome to modern business lending." />
      </Helmet>
      {isQuestionnaire ? (
        <></>
      ) : (
        <Navbar creditResult={creditResult} data={data}
          navBarHeight={navBarHeight} setNavBarHeight={setNavBarHeight}
          navBarHeight2={navBarHeight2} setNavBarHeight2={setNavBarHeight2}
          showNavbar={showNavbar} setShowNavbar={setShowNavbar} />
      )}

      <Grid container rowSpacing={0} justifyContent="center" >
        <Grid item xs={12} md={12} >
          <ScrollToTop />
          <Routes>
            <Route Route path="/" element={<Suspense fallback={<h1>Loading...</h1>}><Home /></Suspense>} />
            <Route Route path="/home" element={<Suspense fallback={<h1>Loading...</h1>}><Home /></Suspense>} />
            <Route path="/about" element={<Suspense fallback={<h1>Loading...</h1>}><About /></Suspense>} />
            <Route path="/contact" element={<Suspense fallback={<h1>Loading...</h1>}><Contact /></Suspense>} />
            <Route path="/sbaexplained" element={<Suspense fallback={<h1>Loading...</h1>}>
              <SBAExplained
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route path="/faq" element={<Suspense fallback={<h1>Loading...</h1>}>
              <FAQ
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/process" element={<Suspense fallback={<h1>Loading...</h1>}><Process /></Suspense>} />
            <Route Route path="/learning" element={<Suspense fallback={<h1>Loading...</h1>}><LearningCenter /></Suspense>} />
            <Route Route path="/forms" element={<Suspense fallback={<h1>Loading...</h1>}><Forms /></Suspense>} />
            <Route Route path="/referral" element={<Suspense fallback={<h1>Loading...</h1>}><Referral /></Suspense>} />
            <Route Route path="/sbaexpress" element={<Suspense fallback={<h1>Loading...</h1>}><SBAExpress /></Suspense>} />
            <Route Route path="/sba7a" element={<Suspense fallback={<h1>Loading...</h1>}><SBA7a /></Suspense>} />
            <Route Route path="/safari" element={<Suspense fallback={<h1>Loading...</h1>}><SafariTest /></Suspense>} />
            <Route Route path="/loanuses/acquisition" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesBusinessAcquisition
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/expansion" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesExpansion
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/construction" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesConstruction
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/refinance" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesRefinance
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/equipment" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesEquipment
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/esop" element={<Suspense fallback={<h1>Loading...</h1>}>
              <ESOP
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/partnerbuyout" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesPartnerBuyout
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/realestate" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesRealEstate
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/startup" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesStartUp
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/loanuses/workingcapital" element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanUsesWorkingCapital
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryPage
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/automotive" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryAutomotive
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/businessservices" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryBusinessServices
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/carwash" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryCarWash
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/childcare" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryChildCare
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/chiropractor" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryChiropractor
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/cpa" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryCPA
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/dental" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryDental
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/eldercare" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryElderCare
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/engineering" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryEngineering
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/fitnesscenters" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryFitnessCenters
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/funeral" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryFuneralHome
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/gasstation" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryGasStation
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/hotel" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryHotels
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/insurance" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryInsurance
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/investmentadvice" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryInvestmentAdvice
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/landscaping" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryLandscaping
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/lawyer" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryLawyer
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/liquorstores" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryLiquorStores
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/medicalprovider" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryMedicalProvider
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/personalservices" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryPersonalServices
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/pharmacy" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryPharmacy
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/restaurant" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryRestaurant
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/retailstores" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryRetailStores
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/specialtytrade" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustrySpecialtyTrade
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/veterinary" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryVeterinary
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/industry/trucking" element={<Suspense fallback={<h1>Loading...</h1>}>
              <IndustryTrucking
                navBarHeight={navBarHeight}
                footerHeight={footerHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path="/calculator/DSCR" element={<Suspense fallback={<h1>Loading...</h1>}>
              <CalculatorDSCR data={data} setData={setData}
                industry={industry} setIndustry={setIndustry}
                industryError={industryError} setIndustryError={setIndustryError}
                loanUse={loanUse} setLoanUse={setLoanUse}
                loanUseError={loanUseError} setLoanUseError={setLoanUseError}
                realEstate={realEstate} setRealEstate={setRealEstate}
                realEstateError={realEstateError} setRealEstateError={setRealEstateError}
                pricingTable={pricingTable}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                qstepdata={qstepdata} setQStepData={setQStepData}
                express={express} setExpress={setExpress}
                dscrData={dscrData} setDSCRData={setDSCRData}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar} /></Suspense>} />
            <Route Route path='/prequalify' element={<Suspense fallback={<h1>Loading...</h1>}>
              <LoanTypeTable data={data} setData={setData}
                industry={industry} setIndustry={setIndustry}
                industryError={industryError} setIndustryError={setIndustryError}
                loanUse={loanUse} setLoanUse={setLoanUse}
                loanUseError={loanUseError} setLoanUseError={setLoanUseError}
                realEstate={realEstate} setRealEstate={setRealEstate}
                realEstateError={realEstateError} setRealEstateError={setRealEstateError}
                pricingTable={pricingTable} setPricingTable={setPricingTable}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                qstepdata={qstepdata} setQStepData={setQStepData}
                express={express} setExpress={setExpress}
                driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                personaltax={personaltax} setPersonalTax={setPersonalTax}
                qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
                qualifyProfitable={qualifyProfitable} setQualifyProfitable={setQualifyProfitable}
                qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
                qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
                qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
                qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
                qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
                qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
                qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry}
                steps={steps} setSteps={setSteps}
                isQuestionnaire={isQuestionnaire} /></Suspense>} />
            <Route Route path='/prequalify/express' element={<Suspense fallback={<h1>Loading...</h1>}>
              <PrequalifyExpress data={data} setData={setData}
                industry={industry} setIndustry={setIndustry}
                industryError={industryError} setIndustryError={setIndustryError}
                loanUse={loanUse} setLoanUse={setLoanUse}
                loanUseError={loanUseError} setLoanUseError={setLoanUseError}
                realEstate={realEstate} setRealEstate={setRealEstate}
                realEstateError={realEstateError} setRealEstateError={setRealEstateError}
                pricingTable={pricingTable}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                qstepdata={qstepdata} setQStepData={setQStepData}
                express={express} setExpress={setExpress} /></Suspense>} />
            <Route Route path='/prequalify/standard' element={<Suspense fallback={<h1>Loading...</h1>}>
              <PrequalifyStandard data={data} setData={setData}
                industry={industry} setIndustry={setIndustry}
                industryError={industryError} setIndustryError={setIndustryError}
                loanUse={loanUse} setLoanUse={setLoanUse}
                loanUseError={loanUseError} setLoanUseError={setLoanUseError}
                realEstate={realEstate} setRealEstate={setRealEstate}
                realEstateError={realEstateError} setRealEstateError={setRealEstateError}
                pricingTable={pricingTable}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                qstepdata={qstepdata} setQStepData={setQStepData}
                express={express} setExpress={setExpress} /></Suspense>} />
            <Route Route path='/credit' element={<Suspense fallback={<h1>Loading...</h1>}>
              <CreditPull data={data} setData={setData}
                pricingTable={pricingTable}
                contactInfo={contactInfo}
                setContactInfo={setContactInfo}
                qstepdata={qstepdata}
                setQStepData={setQStepData}
                creditResult={creditResult} setCreditResult={setCreditResult}
                realEstate={realEstate} /></Suspense>} />
            <Route Route path="/concl" element={<Suspense fallback={<h1>Loading...</h1>}>
              <CreditEndConcl data={data} setCreditResult={setCreditResult} /></Suspense>} />
            <Route Route path="/noscore" element={<Suspense fallback={<h1>Loading...</h1>}>
              <CreditEndNoScore data={data} setCreditResult={setCreditResult}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                personaltax={personaltax} setPersonalTax={setPersonalTax}
                steps={steps} setSteps={setSteps}
                qstepdata={qstepdata} setQStepData={setQStepData}
                qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
                qualifyProfitable={qualifyProfitable} setQualifyProfitable={setQualifyProfitable}
                qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
                qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
                qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
                qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
                qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
                qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
                qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry} /></Suspense>} />
            <Route path="/frozen" element={<Suspense fallback={<h1>Loading...</h1>}>
              <CreditEndFrozen data={data} setCreditResult={setCreditResult} /></Suspense>} />
            <Route path="/congrats" element={<Suspense fallback={<h1>Loading...</h1>}>
              <CreditEndCongrats data={data} setCreditResult={setCreditResult} /></Suspense>} />
            <Route path="/questionnaire/*" element={<Suspense fallback={<h1>Loading...</h1>}>
              <Questionnaire data={data} setData={setData}
                contactInfo={contactInfo} setContactInfo={setContactInfo}
                driverslicense={driverslicense} setDriversLicense={setDriversLicense}
                year1bustax={year1bustax} setYear1BusTax={setYear1BusTax}
                year2bustax={year2bustax} setYear2BusTax={setYear2BusTax}
                personaltax={personaltax} setPersonalTax={setPersonalTax}
                steps={steps} setSteps={setSteps}
                qstepdata={qstepdata} setQStepData={setQStepData}
                qualifyProfit={qualifyProfit} setQualifyProfit={setQualifyProfit}
                qualifyProfitable={qualifyProfitable} setQualifyProfitable={setQualifyProfitable}
                qualifyTaxes={qualifyTaxes} setQualifyTaxes={setQualifyTaxes}
                qualifyDelq={qualifyDelq} setQualifyDelq={setQualifyDelq}
                qualifyDefault={qualifyDefault} setQualifyDefault={setQualifyDefault}
                qualifyBankrupt={qualifyBankrupt} setQualifyBankrupt={setQualifyBankrupt}
                qualifyIncarc={qualifyIncarc} setQualifyIncarc={setQualifyIncarc}
                qualifyCitizen={qualifyCitizen} setQualifyCitizen={setQualifyCitizen}
                qualifyIndustry={qualifyIndustry} setQualifyIndustry={setQualifyIndustry}
                isQuestionnaire={isQuestionnaire}
              /></Suspense>} />
            <Route path="/questionnaire/submit" element={<Suspense fallback={<h1>Loading...</h1>}>
              <SubmitExit data={data} /></Suspense>} />
            
            {/* Catch-all route for undefined paths */}
            <Route path="*" element={<NotFoundPage />} />
            
          </Routes>
        </Grid>
      </Grid>
      {isQuestionnaire ? (
        <></>
      ) : (
        <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
      )}

    </div>
  );
}

export default App;
