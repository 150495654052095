import React from 'react';

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '150px' }}>
      <h1>404</h1>
      <p>Oops! The page you are looking for doesn't exist.</p>
      <a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>
        Go back to Home
      </a>
    </div>
  );
};

export default NotFoundPage;