import { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

const useCustomUrlQuery = ({ data, setData, contactInfo, setContactInfo
    , qstepdata, setQStepData
    , driverslicense, setDriversLicense
    , year1bustax, setYear1BusTax
    , year2bustax, setYear2BusTax
    , personaltax, setPersonalTax
    , qualifyProfit, setQualifyProfit
    , qualifyProfitable, setQualifyProfitable
    , qualifyTaxes, setQualifyTaxes
    , qualifyDelq, setQualifyDelq
    , qualifyDefault, setQualifyDefault
    , qualifyBankrupt, setQualifyBankrupt
    , qualifyIncarc, setQualifyIncarc
    , qualifyCitizen, setQualifyCitizen
    , qualifyIndustry, setQualifyIndustry
    , steps, setSteps }) => {
    //console.log('qstepdata : ',qstepdata)
    
    const location = useLocation();

    function formatAmount(value) {
        if (!value) return value;
        //console.log(value)
        const amountValue = value.toString().replace(/[^\d]/g, '');
        let amountValueTrim = amountValue.length >= 8 ? amountValue.substring(0, 7) : amountValue;
        const amountValueTrimLength = amountValueTrim.length;
        if (amountValueTrimLength < 4) return `$${amountValueTrim}`;
        if (amountValueTrimLength < 7) return `$${amountValueTrim.substring(0, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
        if (amountValueTrimLength < 8) return `$${amountValueTrim.substring(0, amountValueTrim.length - 6)},${amountValueTrim.substring(amountValueTrim.length - 6, amountValueTrim.length - 3)},${amountValueTrim.substring(amountValueTrim.length - 3, amountValueTrim.length)}`;
    }



    //console.log('props.data.datetimesession: ',props.data.datetimesession)
    const [state, setState] = useState({
        querydata: null,
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: ""
    })

    //console.log(props)
    //const propsData = props.data

    function formatSSN(value) {
        if (!value) return value;
        const socialsn = value.replace(/[^\d]/g, '');
        const socialsnLength = socialsn.length;
        if (socialsnLength < 4) return socialsn;
        if (socialsnLength < 6) {
            return `***-${socialsn.slice(3)}`;
        }
        return `***-**-${socialsn.slice(5, 9)}`;
    }

    //console.log('window.location.href : ', window.location.href)
    //console.log('qstepdata.amountformatted : ', qstepdata.amountformatted)
    const checkamt = (qstepdata.amountformatted == "")
    const checkloc = (window.location.href.includes("/questionnaire/"))
    //console.log('checkamt : ', checkamt)
    //console.log('checkloc : ', checkloc)

    useEffect(() => {
        //console.log('window.location.href : ', window.location.href)
        //console.log('contactInfo.firstname : ', contactInfo.firstname)
        if (window.location.href.includes("/questionnaire/") && qstepdata.amountformatted == "" ) {
            setState((s) => ({ ...s, isLoading: true }))
            console.log('starting useCustomUrlQuery fetch')
            fetch('https://j7znldjr50.execute-api.us-east-1.amazonaws.com/v1', {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    customurl: window.location.href
                })
            })
                .then(function (serverPromise) {
                    serverPromise.json()
                        .then(function (j) {

                            //console.log('useQuery props.data.logo: ',props.data.logo);
                            //console.log('j : ',j);
                            //console.log('j[0] : ',j[0]);
                            //console.log('j: ',j[0].BusinessName);
                            //console.log('j email: ',j[0].Email);
                            //console.log(j.body);
                            //console.log(j[0].StartAmount);
                            setState((s) => ({
                                ...s,
                                querydata: j[0],
                                isLoading: false,
                                isSuccess: true,
                                isError: false,
                                error: ""
                            }))

                            const formattedAmount = formatAmount(j[0].Amount_App);
                            //const intcalc = props.data.inerest / 12
                            //const termcalc = props.data.term * 12
                            //const calc1 = (1 + intcalc) ** termcalc
                            //const pmtcalc=data.amount*(intcalc*calc1)/(calc1-1)
                            //const pmtcalc = props.data.amount * ((props.data.inerest / 12) * ((1 + (props.data.inerest / 12)) ** (props.data.term * 12))) / (((1 + (props.data.inerest / 12)) ** (props.data.term * 12)) - 1)

                            const BusEstDate = j[0].EstablishedDate_App == "1900-01-01" ? "" : new Date(j[0].EstablishedDate_App)
                            setQStepData({
                                ...qstepdata
                                , entityType: j[0].BusinessType_App
                                , employees: j[0].Employees_App
                                , TIN: j[0].TIN_App
                                , dateBusinessEst: dayjs(BusEstDate)
                                , busStreet1: j[0].BusinessAdd1_App
                                , busStreet2: j[0].BusinessAdd2_App
                                , busCity: j[0].BusinessCity_App
                                , busState: j[0].BusinessState_App
                                , busZip: j[0].BusinessZip5_App
                                , ownernum: j[0].OwnerNum_App
                                , amountformatted: formattedAmount
                                , legalBusinessName: j[0].LegalBusName_App
                                , submitted: j[0].Submitted
                                , applicantID: j[0].ApplicantID
                                , website: j[0].Website
                                , real_estate: j[0].RealEstate

                                , owner1FirstName: j[0].PrimaryFirstName_App
                                , owner1LastName: j[0].PrimaryLastName_App
                                , owner1Perc: j[0].PrimaryOwnership_App
                                , owner1Email: j[0].PrimaryEmail_App
                                , owner1HomeAddress1: j[0].PrimaryStreet1_App
                                , owner1HomeAddress2: j[0].PrimaryStreet2_App
                                , owner1City: j[0].PrimaryCity_App
                                , owner1State: j[0].PrimaryState_App
                                , owner1Zip: j[0].PrimaryZip5_App
                                , owner1SSN: formatSSN(j[0].PrimarySSN_App)
                                , owner1SSNoriginal: j[0].PrimarySSN_App
                                , owner1Phone: j[0].PrimaryPhone_App

                                , owner2FirstName: j[0].Owner2FirstName_App
                                , owner2LastName: j[0].Owner2LastName_App
                                , owner2Perc: j[0].Owner2Ownership_App
                                , owner2Email: j[0].Owner2Email_App
                                , owner2Phone: j[0].Owner2Phone_App
                                //owner2SSN: ""

                                , owner3FirstName: j[0].Owner3FirstName_App
                                , owner3LastName: j[0].Owner3LastName_App
                                , owner3Perc: j[0].Owner3Ownership_App
                                , owner3Email: j[0].Owner3Email_App
                                , owner3Phone: j[0].Owner3Phone_App
                                //owner3SSN: ""

                                , owner4FirstName: j[0].Owner4FirstName_App
                                , owner4LastName: j[0].Owner4LastName_App
                                , owner4Perc: j[0].Owner4Ownership_App
                                , owner4Email: j[0].Owner4Email_App
                                , owner4Phone: j[0].Owner4Phone_App
                                //owner4SSN: ""

                                , owner5FirstName: j[0].Owner5FirstName_App
                                , owner5LastName: j[0].Owner5LastName_App
                                , owner5Perc: j[0].Owner5Ownership_App
                                , owner5Email: j[0].Owner5Email_App
                                , owner5Phone: j[0].Owner5Phone_App
                                //owner5SSN: ""

                                , qDriversLicenseName: j[0].DriverLicense_FileName
                                , qBusTax1Name: j[0].BusTax1_FileName
                                , qBusTax2Name: j[0].BusTax2_FileName
                                , qPersonalTaxName: j[0].PersonalTax_FileName
                            })
                            //console.log('qstepdata : ', qstepdata)
                            //console.log('new Date(j[0].EstablishedDate_App) : ', new Date(j[0].EstablishedDate_App))
                            //setQStepData({...qstepdata, dateBusinessEstString: (new Date(j[0].EstablishedDate_App))})
                            //console.log('formattedAmount : ', formattedAmount)


                            setQualifyProfit(j[0].QualifyProfit_App);
                            setQualifyProfitable(j[0].QualifyProfitable_App);
                            setQualifyCitizen(j[0].QualifyCitizen_App);
                            setQualifyTaxes(j[0].QualifyTaxes_App);
                            setQualifyDelq(j[0].QualifyDelinquent_App);
                            setQualifyDefault(j[0].QualifyDefault_App);
                            setQualifyBankrupt(j[0].QualifyBankrupt_App);
                            setQualifyIncarc(j[0].QualifyIncarcerated_App);
                            setQualifyIndustry(j[0].QualifyIndustry_App);

                            setDriversLicense({ ...driverslicense, name: j[0].DriverLicense_FileName });
                            setYear1BusTax({ ...year1bustax, name: j[0].BusTax1_FileName });
                            setYear2BusTax({ ...year2bustax, name: j[0].BusTax2_FileName });
                            setPersonalTax({ ...personaltax, name: j[0].PersonalTax_FileName });

                            setContactInfo({
                                ...contactInfo
                                , firstname: j[0].FirstName
                                , lastname: j[0].LastName
                                , businessname: j[0].BusinessName
                                //    , amount: j[0].Amount
                            })

                            setData({
                                ...data
                                , amount: j[0].Amount_App
                                , reactKey: j[0].ReactKey
                                , amountformatted: formattedAmount
                                , interest: j[0].Interest
                                , prime: j[0].Prime
                                , margin: j[0].Margin
                                , term: j[0].Term
                                , payment: j[0].Payment
                            })
                            //console.log('qualifyProfit 1 : ', qualifyProfit)                            

                        })
                        .catch(function (e) {
                            console.log('e:', e);
                            setState((s) => ({
                                ...s,
                                querydata: null,
                                isLoading: false,
                                isSuccess: false,
                                isError: true,
                                error: e.message || "Failed to fetch"
                            }))
                        })

                })
                .catch(function (e) {
                    console.log('e:', e);
                    setState((s) => ({
                        ...s,
                        querydata: null,
                        isLoading: false,
                        isSuccess: false,
                        isError: true,
                        error: e.message || "Failed to fetch"
                    }))
                })




        } else {
            setState((s) => ({
                ...s,
                querydata: null,
                isLoading: false,
                isSuccess: false,
                isError: false,
            }))
            //console.log('skip custom url query')
        }


    }, [window.location.href])

    useEffect(() => {
        // Step 1 complete
        if (qstepdata.legalBusinessName != ""
            && qstepdata.entityType != ""
            && qstepdata.employees != ""
            && qstepdata.TIN.length == "11"
            && qstepdata.dateBusinessEst != ""
            && qstepdata.busStreet1 != ""
            && qstepdata.busCity != ""
            && qstepdata.busState != ""
            && qstepdata.busZip.length == "5"
        ) {
            setSteps(steps => ({ ...steps, 0: true }));
            //console.log('step6 complete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        } else {
            setSteps(steps => ({ ...steps, 0: false }));
            //console.log('step6 incomplete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        }

        // Step 2 complete
        if (qstepdata.owner1FirstName != ""
            && qstepdata.owner1LastName != ""
            && qstepdata.owner1SSN.length == "11"
            && qstepdata.owner1Perc.length >= "2"
            && qstepdata.owner1HomeAddress1 != ""
            && qstepdata.owner1City != ""
            && qstepdata.owner1State != ""
            && qstepdata.owner1Zip.length == "5"
            && qstepdata.owner1Email.includes("@")
            && qstepdata.owner1Email.includes(".")
            && qstepdata.owner1Phone.length == 14
            && (
                qstepdata.ownernum == "1"
                ||
                (qstepdata.ownernum == "2"
                    && qstepdata.owner2FirstName != ""
                    && qstepdata.owner2LastName != ""
                    && qstepdata.owner2Perc.length >= "2"
                    && qstepdata.owner2Email.includes("@")
                    && qstepdata.owner2Email.includes(".")
                    && qstepdata.owner2Phone.length == 14
                )
                ||
                (qstepdata.ownernum == "3"
                    && qstepdata.owner2FirstName != ""
                    && qstepdata.owner2LastName != ""
                    && qstepdata.owner2Perc.length >= "2"
                    && qstepdata.owner2Email.includes("@")
                    && qstepdata.owner2Email.includes(".")
                    && qstepdata.owner2Phone.length == 14

                    && qstepdata.owner3FirstName != ""
                    && qstepdata.owner3LastName != ""
                    && qstepdata.owner3Perc.length >= "2"
                    && qstepdata.owner3Email.includes("@")
                    && qstepdata.owner3Email.includes(".")
                    && qstepdata.owner3Phone.length == 14
                )
                ||
                (qstepdata.ownernum == "4"
                    && qstepdata.owner2FirstName != ""
                    && qstepdata.owner2LastName != ""
                    && qstepdata.owner2Perc.length >= "2"
                    && qstepdata.owner2Email.includes("@")
                    && qstepdata.owner2Email.includes(".")
                    && qstepdata.owner2Phone.length == 14

                    && qstepdata.owner3FirstName != ""
                    && qstepdata.owner3LastName != ""
                    && qstepdata.owner3Perc.length >= "2"
                    && qstepdata.owner3Email.includes("@")
                    && qstepdata.owner3Email.includes(".")
                    && qstepdata.owner3Phone.length == 14

                    && qstepdata.owner4FirstName != ""
                    && qstepdata.owner4LastName != ""
                    && qstepdata.owner4Perc.length >= "2"
                    && qstepdata.owner4Email.includes("@")
                    && qstepdata.owner4Email.includes(".")
                    && qstepdata.owner4Phone.length == 14
                )
                ||
                (qstepdata.ownernum == "5"
                    && qstepdata.owner2FirstName != ""
                    && qstepdata.owner2LastName != ""
                    && qstepdata.owner2Perc.length >= "2"
                    && qstepdata.owner2Email.includes("@")
                    && qstepdata.owner2Email.includes(".")
                    && qstepdata.owner2Phone.length == 14

                    && qstepdata.owner3FirstName != ""
                    && qstepdata.owner3LastName != ""
                    && qstepdata.owner3Perc.length >= "2"
                    && qstepdata.owner3Email.includes("@")
                    && qstepdata.owner3Email.includes(".")
                    && qstepdata.owner3Phone.length == 14

                    && qstepdata.owner4FirstName != ""
                    && qstepdata.owner4LastName != ""
                    && qstepdata.owner4Perc.length >= "2"
                    && qstepdata.owner4Email.includes("@")
                    && qstepdata.owner4Email.includes(".")
                    && qstepdata.owner4Phone.length == 14

                    && qstepdata.owner5FirstName != ""
                    && qstepdata.owner5LastName != ""
                    && qstepdata.owner5Perc.length >= "2"
                    && qstepdata.owner5Email.includes("@")
                    && qstepdata.owner5Email.includes(".")
                    && qstepdata.owner5Phone.length == 14
                )
            )
        ) {
            setSteps(steps => ({ ...steps, 1: true }));
            //console.log('step6 complete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        } else {
            setSteps(steps => ({ ...steps, 1: false }));
            //console.log('step6 incomplete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        }

        // Step 3 complete
        //console.log('qualifyProfit : ', qualifyProfit)
        if (qualifyProfit != null
            && qualifyProfitable != null
            && qualifyTaxes != null
            && qualifyDelq != null
            && qualifyDefault != null
            && qualifyBankrupt != null
            && qualifyIncarc != null
            && qualifyCitizen != null
            && qualifyIndustry != null
        ) {
            setSteps(steps => ({ ...steps, 2: true }));
            //console.log('step6 complete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        } else {
            setSteps(steps => ({ ...steps, 2: false }));
            //console.log('step6 incomplete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
        }

        // Step 4 complete
        if ((driverslicense.name != 'No file chosen' ) //|| qstepdata.qDriversLicenseName != null) 
            && (year1bustax.name != 'No file chosen' )//|| qstepdata.qBusTax1Name != null)  
            && (year2bustax.name != 'No file chosen' )//|| qstepdata.qBusTax2Name != null)  
            && (personaltax.name != 'No file chosen' ) ) {//|| qstepdata.qPersonalTaxName != null) ) {
                setSteps(steps => ({ ...steps, 3: true }));
                //console.log('step6 complete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
            } else {
                setSteps(steps => ({ ...steps, 3: false }));
                //console.log('step6 incomplete: ', driverslicense.size, year1bustax.size, year2bustax.size, personaltax.size)
            }
        
    }, [driverslicense, year1bustax, year2bustax, personaltax, qualifyProfit, qualifyProfitable
        , qualifyTaxes, qualifyDelq, qualifyDefault, qualifyBankrupt, qualifyIncarc, qualifyCitizen, qualifyIndustry
        , qstepdata.ownernum, qstepdata.owner1FirstName, qstepdata.owner1LastName
        , qstepdata.owner1SSN, qstepdata.owner1Perc, qstepdata.owner1HomeAddress1, qstepdata.owner1City
        , qstepdata.owner1State, qstepdata.owner1Zip, qstepdata.owner1Email, qstepdata.owner1Phone
        , qstepdata.owner2FirstName, qstepdata.owner2LastName, qstepdata.owner2Perc, qstepdata.owner2Email, qstepdata.owner2Phone
        , qstepdata.owner3FirstName, qstepdata.owner3LastName, qstepdata.owner3Perc, qstepdata.owner3Email, qstepdata.owner3Phone
        , qstepdata.owner4FirstName, qstepdata.owner4LastName, qstepdata.owner4Perc, qstepdata.owner4Email, qstepdata.owner4Phone
        , qstepdata.owner5FirstName, qstepdata.owner5LastName, qstepdata.owner5Perc, qstepdata.owner5Email, qstepdata.owner5Phone
        , qstepdata.legalBusinessName, qstepdata.entityType, qstepdata.employees
        , qstepdata.TIN, qstepdata.dateBusinessEst, qstepdata.busStreet1, qstepdata.busCity
        , qstepdata.busState, qstepdata.busZip, location.pathname, location.search
    ])

    return state

};

export default useCustomUrlQuery